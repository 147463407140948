$(document).ready(function () {

    $(this).loadFunctions();

    $(function () {
        $.ajaxSetup({
            headers: {
                'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
            }
        });
    });
});

var toastOptions = {
    "autohide": false,
    "delay": 15000,
}

$.fn.loadFunctions = function () {

    $(this).loadToast();
    $(this).loadModal();
    $(this).loadFormValidation();
    $(this).loadRegisterFormFunctions();
    $(this).loadFileUploader();
    $(this).loadPaymentFunctions();
    $(this).loadInkassonummerFunctions();

};

$.fn.loadToast = function () {

    $('.toast', this).toast(toastOptions);

    $('.toast', this).each(function () {
        $(this).toast('show')
    });
};

$.fn.loadModal = function () {
    $('[data-modal-url]', this).on('click', function () {

        $('#oeid-modal .modal-dialog').load($(this).data('modalUrl'), function () {
            $('#oeid-modal .modal-dialog').loadFunctions();
        });

        $('#oeid-modal').modal({show: true});
    });
};

$.fn.loadFormValidation = function () {

    var forms = $('.needs-validation', this);

    // Loop over them and prevent submission
    var validation = Array.prototype.filter.call(forms, function (form) {
        form.addEventListener('submit', function (event) {
            if (form.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
            }
            form.classList.add('was-validated');
        }, false);
    });
};

$.fn.loadFileUploader = function () {

    this.on('change', '.file-input', async function () {

        var file = this.files[0];

        var formData = new FormData();

        // add assoc key values, this will be posts values
        formData.append("file", file, file.name);
        formData.append("upload_file", true);


        let newMessage = $("<div class='alert alert-info'>Datei '" + file.name.replace(/[^a-z0-9_.\-]/gi, '_').toLowerCase() + "' wird hochgeladen.</div>");
        $(".upload-feedback").append(newMessage)

        $.ajax(
            '/kontakt/upload',
            {
                accepts: "json",
                type: "POST",
                data: formData,
                processData: false,  // tell jQuery not to process the data
                contentType: false,  // tell jQuery not to set contentType

                success: function (data) {
                    var responseStatus = data.status;
                    var responseMessage = data.msg;
                    if (responseStatus === 'success') {
                        newMessage.addClass('alert-success')
                    } else {
                        newMessage.addClass('alert-danger')
                    }
                    newMessage
                        .removeClass('alert-info')
                        .html(responseMessage);
                },

                error: function (error) {

                    var errorMsgs = $.map(error.responseJSON.errors, function (value, index) {
                        return [value];
                    });

                    var msg = errorMsgs.join("<br />")

                    newMessage
                        .addClass('alert-danger')
                        .removeClass('alert-info')
                        .html(msg);
                },
            })
    });

};

$.fn.loadRegisterFormFunctions = function () {

    $('#register-check-button', this).click(function () {

        if ($('#register-form')[0].checkValidity()) {

            $.ajax({
                url: "/check-activation-code",
                method: 'post',
                data: $('#register-form').serializeArray(),
                success: function (result) {

                    console.log(result);

                    if (result.result == 'error') {

                        $('#check-error .alert').html(result.errorMsg)
                        $('#check-error').collapse('show');

                    } else {

                        $('#check-error').collapse('hide');

                        $('#actcode').prop("readonly", true);
                        $('#register-user').prop("readonly", true);
                        $('.mail-form-group').collapse('show');

                        $('.mail-form-group input').prop('disabled', false);

                        $('#register-submit-button').removeClass("d-none");
                        $('#register-check-button').addClass("d-none");

                    }
                }
            });
        }
    });

    $('#register-submit-button', this).click(function () {

        if ($('#register-form')[0].checkValidity()) {

            $.ajax({
                url: "/activate",
                method: 'post',
                data: $('#register-form').serializeArray(),
                success: function (result) {

                    if (result.status ==='error'){
                        addToast(result.message, 'danger')
                    }else{
                        $('#oeid-modal').modal('hide')
                        addToast("Sie erhalten in Kürze einen Aktivierungslink per Mail.", 'success')
                    }

                }
            });
        }
    });

    $('#login-submit-button', this).click(function (event) {
        event.preventDefault();

        if ($('#login-form')[0].checkValidity()) {

            var data = $('#login-form').serializeArray();

            var url = "/authenticate"
            if (data[2].name === 'email')
                url = 'resend-actcode';

            $.ajax({
                url: url,
                method: 'post',
                data: $('#login-form').serializeArray(),
                success: function (result) {

                    if (result.result === 'resend'){

                    $('#reset-placeholder')
                        .html(result.content)
                        .loadFunctions();

                    $('#user').prop("readonly", true);
                    $('#password').prop("readonly", true);

                    $('#oeid-modal').modal({show: true});
                    }else if (result.result === 'refresh'){
                        location.reload();
                    }

                }
            });
        }
    });
};

$.fn.loadPaymentFunctions = function () {

    $('#schupo-payment-button', this).click(function (event) {

        event.preventDefault();
        if ($('#schupo-payment-form')[0].checkValidity()) {

            $.ajax({
                url: "/payment-anonym",
                method: 'post',
                data: $('#schupo-payment-form').serializeArray(),
                success: function (result) {

                    $('#oeid-modal .modal-dialog')
                        .html(result)
                        .loadFunctions();

                    $('#oeid-modal').modal({show: true});

                }
            });
        }
    });
};

$.fn.loadInkassonummerFunctions = function () {

    $('#inkassonummer-ratenzahlung').change(function () {
        if ($(this).val() == '') {
            $('#rate').prop('disabled', true);
            $('#min').val('');
            $('#max').val('');
        } else {
            var option = $('option:selected', $(this));
            var min = option.attr('data-min');
            var max = option.attr('data-max')
            $('#min').val(min);
            $('#max').val(max);
            var min = parseFloat(min.split('.').join('').replace(',', '.'));
            var max = parseFloat(max.split('.').join('').replace(',', '.'));
            $('#rate')
                .prop({
                    'disabled': false,
                    'max': max,
                    'min': min
                })
                .focus();
        }
    });

    $('#inkassonummer-payment').change(function () {
        var option = $('option:selected', $(this));
        var min = option.attr('data-min');
        var max = option.attr('data-max');
        min = min.split('.').join('').replace(',', '.');
        max = max.split('.').join('').replace(',', '.');
        $('#betrag')
            .prop({
                'max': max,
                'min': min
            });
    });
};

function addToast(message, level = 'info') {

    console.log('addToast called');

    let toastHtml = '            <div class="toast mt-2 mx-auto position-absolute" style="z-index: 1" role="alert" aria-live="assertive"\n' +
        '                 aria-atomic="true">\n' +
        '                <div class="toast-body alert-' + level + '">\n' +
        '                    <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">\n' +
        '                        <span aria-hidden="true">&times;</span>\n' +
        '                    </button>\n' +
        '                   ' + message +
        '                </div>\n' +
        '            </div>';

    let newToast = $(toastHtml);
    newToast.appendTo('#messages-container');

    console.log('toast appendet to #messages-container');

    newToast.toast(toastOptions);
    newToast.toast('show')
}


